import axios from 'axios';
import http, { getAll } from './http';

export const getProductosCar = async (currency, companyId, list) => {
    http.defaults.headers.get['X-Currency'] = currency;
    let arr = list.map((p) => {
        return http.get(
            `/${companyId}/branch-goods/${p.id}/?with=branch_good_properties`
        );
        //  return http.get(`/goods/${p.id}/?with=properties`)
    });
    let resp = await getAll(arr);
    return resp;
};
export const getCurrencies = async (currency, companyId, branchId) => {
    http.defaults.headers.get['X-Currency'] = currency;
    let resp = await http.get(`/${companyId}/branches/${branchId}/currencies`);
    console.log(resp);
    return resp;
};
export const convertCurrency = async (
    currencyFrom,
    currencyTo,
    amount,
    companyId,
    branchId
) => {
    let resp = await http.get(
        `/${companyId}/branches/${branchId}/currency-convert?currency_iso_from=${currencyFrom}&currency_iso_to=${currencyTo}&amount_e2=${parseInt(
            amount * 100
        )}`
    );
    console.log(resp);
    return resp;
};
export const getTokenLuka = async (body) => {
    let resp = await http.post(
        `https://bspaycoapi-qa.payco.net.ve/api/v1/servicio/login`,
        JSON.stringify(body),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
    return resp;
};
export const getTokenLukaProd = async (comercioId, paisId) => {
    let resp = await axios.get(
        `${process.env.REACT_APP_URL_TM}configuracion/tokenluka/${comercioId}/${paisId}`
    );
    return resp;
};

export const crearOrden = async (data, Iso) => {
    //  console.log(token);
    let resp = await axios.post(
        `${process.env.REACT_APP_URL_TM}services/dondemand/create_order`,
        data,
        {
            headers: {
                'X-Currency': Iso
            }
        }
    );
    console.log(resp);
    return resp;
};

export const processOrden = async (data, Iso) => {
    //  console.log(token);
    let resp = await axios.post(
        `${process.env.REACT_APP_URL_TM}compra-merch/process-order`,
        data
    );
    console.log(resp);
    return resp;
};
