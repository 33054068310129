/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Layout from '../components/layout';
import Seo from '../components/seo';

import DetailCarPay from '../components/car/DetailCarPay';
import LoginComponent from '../components/login/loginComponent';
import Lukapay from '../components/car/Lukapay';

import {
    convertCurrency,
    crearOrden,
    getProductosCar,
    processOrden
} from '../services/car';
import { formatMontoR, formatMonto } from '../components/utils';
import Skeleton from '../components/car/skeletonLuka';
import { useNavigate } from 'react-router-dom';
import { setInfoUser } from '../store/usuarioSlice';
import moment from 'moment';
import 'moment/locale/es';
import { useTranslation } from 'react-i18next';
moment.locale('es');
// markup
const RealizarPago = () => {
    const { t } = useTranslation();
    const shop = useSelector((store) => store.shop);
    // const [Currencies] = useState(['USD', 'VEF']);
    const [Total, setTotal] = useState(0);
    const [SubTotal, setSubTotal] = useState(0);
    const [Productos, setProductos] = useState([]);
    const [, setMontoGen] = useState(0);
    const [MontoConv, setMontoConv] = useState(0);
    const [, setCantidadProds] = useState(0);
    const [moneda, setMoneda] = useState(shop.config.ShopCurrency || 'USD');
    const [DatosLuka, setDatosLuka] = useState({
        moneda,
        monto: MontoConv
    });

    const dispatch = useDispatch();

    const [converting, setConverting] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [pagoExitoso, setPagoExitoso] = useState(null);

    const [setErrors] = useState({});
    const [updateLib] = useState(new Date());

    const carStore = useSelector((store) => store.car);
    const usuario = useSelector((store) => store.usuario.datos);
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            if (carStore.productos.length > 0) {
                let pArray = [];
                let resp = await getProductosCar(
                    shop.config.ShopCurrency,
                    shop.config.CompanyID,
                    carStore.productos
                );
                // console.log(resp);
                resp.map((r) => {
                    if (r.status === 200) {
                        pArray.push(r.data);
                    }
                });
                setProductos(pArray || []);
            }
        })();
    }, []);

    useEffect(() => {
        // console.log(Productos);
        if (Productos != null && Productos.length > 0) {
            let acum = 0;
            let cant = 0;
            carStore.productos.map((p) => {
                let producto = Productos.find((n) => n.id === p.id);
                let _tV = getTotalConVariantes(producto, p);
                acum += parseFloat(_tV) * p.cantidad;
                cant = cant + p.cantidad;
            });
            setSubTotal(acum);
            setCantidadProds(cant);
            let monto = moneda === 'USD' ? acum : acum;
            setTotal(formatMontoR(monto, 2, ',', '.'));
            console.log(formatMontoR(monto, 2, ',', '.'));
            setMontoConv(monto);
            setDatosLuka({ monto, moneda });
        }
    }, [Productos, carStore.productos]);

    // CAMBIO DE MONEDA
    useEffect(() => {
        // console.log(Productos);
        // if (Productos != null && Productos.length > 0) {
        //     let acum = 0;
        //     let cant = 0;
        //     carStore.productos.map((p) => {
        //         let producto = Productos.find((n) => n.id === p.id);
        //         let _tV = getTotalVariantes(producto, p);
        //         acum +=
        //             (parseFloat(
        //                 formatMonto(
        //                     producto.custom_data.display_price_e2,
        //                     2,
        //                     ',',
        //                     '.'
        //                 )
        //             ) +
        //                 _tV) *
        //             p.cantidad;
        //         cant = cant + p.cantidad;
        //     });
        //     setSubTotal(acum);
        //     setCantidadProds(cant);
        //     let monto =
        //         moneda === 'USD'
        //             ? acum
        //             : acum *
        //               parseFloat(
        //                   Currencies.find((n) => n.iso === moneda)
        //                       .conversion_factor
        //               ).toFixed(2);
        //     setTotal(formatMontoR(monto, 2, ',', '.'));
        //     setMontoConv(monto);
        //     setDatosLuka({ monto, moneda });
        // }
        if (shop.config.ShopCurrencyConvert) {
            if (moneda !== 'USD') {
                convertir(
                    'USD',
                    moneda,
                    Total,
                    shop.config.CompanyID,
                    shop.config.BranchID
                );
            } else {
                setDatosLuka({
                    moneda,
                    monto: MontoConv
                });
            }
        }
    }, [moneda]);

    useEffect(() => {
        // if (pagoExitoso && pagoExitoso.Exitoso) {
        // }
    }, [pagoExitoso]);

    const convertir = async (
        currencyFrom,
        CurrencyTo,
        amount,
        companyId,
        branchId
    ) => {
        setConverting(true);
        const resp = await convertCurrency(
            currencyFrom,
            CurrencyTo,
            amount,
            companyId,
            branchId
        );
        console.log(resp);
        setConverting(false);
        setDatosLuka({
            monto: resp.data.to.amount_e2 / 100,
            moneda: CurrencyTo
        });
    };

    const validarDatos = async () => {
        let isValid = true;
        let _E = {};
        if (document.getElementById('email') !== null) {
            return true;
        }

        if (document.getElementById('pais').value === '') {
            _E = { ..._E, errorPais: 'Debe seleccionar un país' };
            isValid = false;
        }
        if (document.getElementById('direccion').value === '') {
            _E = { ..._E, errorDireccion: 'Debe colocar una dirección válida' };
            isValid = false;
        }
        if (document.getElementById('referencia').value === '') {
            _E = {
                ..._E,
                errorReferencia: 'Debe colocar un punto de referencia válido'
            };
            isValid = false;
        }
        setErrors(_E);
        return isValid;
    };

    const procesarPago = async (infoPago) => {
        console.log(infoPago);
        if (infoPago.Exitoso) {
            setPagoExitoso(infoPago);
            let goods = carStore.productos.map((p) => {
                let good = {
                    good_id: p.good_id,
                    quantity: p.cantidad,
                    properties: []
                };
                if (Object.entries(p.variantes).length > 0) {
                    let arr = Object.keys(p.variantes).map((v) => {
                        return {
                            property_id: parseInt(v.replace('op_', '')),
                            value: p.variantes[v]
                        };
                    });
                    good.properties = arr;
                }
                return good;
            });
            if (goods.length > 0) {
                const order = await crearOrden(
                    {
                        function_id: usuario[0].funcion.Id,
                        client_name: usuario[0].cliente.Nombre,
                        client_email: usuario[0].cliente.Email,
                        goods
                    },
                    moneda
                );
                if (order.data.has_error) {
                    toast.error(
                        'Error al procesar la orden, intente de nuevo',
                        {
                            position: toast.POSITION.TOP_RIGHT
                        }
                    );
                    return false;
                } else {
                    let procesar = await processOrden({
                        email: usuario[0].cliente.Email,
                        order_id: order.data.service_payload.order.id,
                        otp_code: null,
                        function_id: usuario[0].funcion.Id,
                        event_id: shop.config.EventID
                    });
                    console.log(procesar);
                    navigate('/compraFinalizada', {
                        state: {
                            car: { ...carStore },
                            orden: {
                                symbol: moneda,
                                subTotal: SubTotal,
                                total: Total,
                                productos: Productos,
                                orderId: order.data.service_payload.order.id
                            }
                        }
                    });
                }
            }
        } else {
            toast.error(
                'Error al procesar transacción, verifique e intente nuevamente',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                }
            );
            setTimeout(() => {
                // location.reload();
            }, 3500);
        }
    };

    const noEmail = async () => {
        toast.error('Debes iniciar sesión o registrarte para continuar', {
            position: toast.POSITION.TOP_RIGHT
        });
    };
    return (
        <Layout>
            <Seo title={`Shop - ${t('RealizarPago')}`} />
            <div id="pagePago" className={`${converting ? '' : ''}`}>
                <section className="container">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <i
                                className="icon-angle-left back"
                                onClick={() => navigate(-1)}
                            ></i>
                            <div className="datos">
                                <h2 className="textSubTitle">
                                    {t('TusDatos')}
                                </h2>
                                <div className="wrapUsuario">
                                    <div className="usuario">
                                        <input
                                            type="hidden"
                                            id="email"
                                            value={
                                                usuario
                                                    ? usuario[0].cliente.Email
                                                    : ''
                                            }
                                        />
                                        <input
                                            type="hidden"
                                            id="name"
                                            value={
                                                usuario
                                                    ? usuario[0].cliente.Nombre
                                                    : ''
                                            }
                                        />
                                        {!usuario ? (
                                            <LoginComponent hideTitle={true} />
                                        ) : (
                                            <div className="datosUser">
                                                <p>
                                                    {usuario[0].cliente.Email}
                                                </p>
                                                <br />
                                                <p>{t('AgregadosValidos')}</p>
                                                <p>
                                                    <b>
                                                        {
                                                            usuario[0].funcion
                                                                .EventoNombre
                                                        }
                                                    </b>
                                                </p>
                                                <p>
                                                    {moment(
                                                        usuario[0].funcion.Fecha
                                                    ).format('DD MMMM YYYY')}
                                                </p>
                                                <button
                                                    className="btn btn-block btnOutline"
                                                    onClick={() =>
                                                        dispatch(
                                                            setInfoUser(null)
                                                        )
                                                    }
                                                >
                                                    {t('CambiarUsuario')}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="compra d-lg-none mt-5">
                                    <DetailCarPay
                                        updateMonto={setMontoGen}
                                        Productos={Productos}
                                        Total={Total}
                                        SubTotal={SubTotal}
                                        moneda={moneda}
                                        symbol={''}
                                        // setShowMoneda={setShowMoneda}
                                    />
                                </div>
                                <div
                                    className="metodosWrap"
                                    style={
                                        usuario
                                            ? {
                                                  opacity: 1,
                                                  pointerEvents: 'auto',
                                                  cursor: 'auto'
                                              }
                                            : {
                                                  opacity: 0,
                                                  pointerEvents: 'none',
                                                  cursor: 'not-allowed'
                                              }
                                    }
                                >
                                    {MontoConv > 0 &&
                                        usuario &&
                                        !converting && (
                                            <Lukapay
                                                monto={DatosLuka.monto}
                                                moneda={DatosLuka.moneda}
                                                noEmail={noEmail}
                                                update={updateLib}
                                                setShowSkeleton={
                                                    setShowSkeleton
                                                }
                                                showSkeleton={showSkeleton}
                                                procesarPago={procesarPago}
                                                validarDatos={validarDatos}
                                                nombre={
                                                    usuario[0].cliente.Nombre
                                                }
                                            />
                                        )}
                                    {(showSkeleton || converting) && (
                                        <Skeleton />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 d-none d-lg-flex">
                            <div className="compra">
                                <DetailCarPay
                                    setMoneda={setMoneda}
                                    updateMonto={setMontoGen}
                                    Productos={Productos}
                                    Total={Total}
                                    totalConvert={DatosLuka.monto || 0}
                                    SubTotal={SubTotal}
                                    moneda={moneda}
                                    symbol={''}
                                    converting={converting}
                                    // setShowMoneda={setShowMoneda}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default RealizarPago;

// const getTotalVariantes = (productoOrigin, pCar) => {
//     let _tV = 0;
//     Object.keys(pCar.variantes).map((v) => {
//         let op = productoOrigin.branch_good_properties
//             .find((n) => n.id === parseInt(v.replace('op_', '')))
//             ?.property_options.find((n) => n.id === pCar.variantes[v]);
//         if (op) {
//             _tV += parseFloat(formatMonto(op.price_e2, 2, ',', '.'));
//         }
//     });
//     return _tV;
// };

const getTotalConVariantes = (productoOrigin, pCar) => {
    let _tV = parseFloat(
        formatMonto(
            productoOrigin.custom_data.conversion.display_price_e2,
            2,
            ',',
            '.'
        )
    );
    // let _propDet = '';
    Object.keys(pCar.variantes).map((v) => {
        // let prop = productoOrigin.branch_good_properties.find(
        //     (n) => n.property_id === parseInt(v.replace('op_', ''))
        // );
        let op = productoOrigin.branch_good_properties
            .find((n) => n.property_id === parseInt(v.replace('op_', '')))
            ?.property_options.find((n) => n.id === pCar.variantes[v]);
        if (op) {
            _tV += parseFloat(formatMonto(op.price_e2, 2, ',', '.'));
        }
        // _propDet =
        //     prop && op
        //         ? `${prop.display_name}: ${op.name} (${formatMonto(
        //               op.price_e2,
        //               2,
        //               ',',
        //               '.'
        //           )})`
        //         : '';
    });
    return _tV;
};
