/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { getProductosCar } from '../../services/car';
import { formatMonto } from '../utils';
import ButtonMinus from './buttonMinus';
import ButtonPlus from './buttonPlus';
import ButtonTrash from './ButtonTrash';
import { clearCar, setShowCar } from '../../store/carSlice';
const DetailCar = () => {
    const { t } = useTranslation();
    const carStore = useSelector((state) => state.car);
    const shop = useSelector((state) => state.shop);
    const [SubTotal, setSubTotal] = useState(0);
    const [CantidadProds, setCantidadProds] = useState(0);
    const [Descuento] = useState(0);
    const [Productos, setProductos] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (carStore.showCar && carStore.productos.length > 0) {
                let pArray = [];
                let resp = await getProductosCar(
                    shop.config.ShopCurrency,
                    shop.config.CompanyID,
                    carStore.productos
                );
                resp.map((r) => {
                    if (r.status === 200) {
                        pArray.push(r.data);
                    }
                });
                setProductos(pArray || []);
            }
        })();
    }, []);

    useEffect(() => {
        let cant = 0;
        carStore.productos.map((p) => {
            cant = cant + p.cantidad;
        });
        setCantidadProds(cant);
    }, [carStore.productos]);

    useEffect(() => {
        if (Productos !== null) {
            let acum = 0;
            let cant = 0;
            carStore.productos.map((p) => {
                // console.log(p.variantes);
                let producto = Productos.find((n) => n.id === p.id);
                let { TotalVariante: _tV } = getTotalConVariantes(producto, p);
                console.log(_tV)
                acum += parseFloat(_tV) * p.cantidad;
                cant = cant + p.cantidad;
            });
            setSubTotal(acum);
            setCantidadProds(cant);
        }
    }, [Productos, carStore.productos]);
    return (
        <section id="detailCarTop">
            <header>
                <p>{t('TienesProductos', { cant: CantidadProds })}</p>
            </header>
            <section>
                {Productos === null ? (
                    <div className="d-flex align-self-center mx-auto">
                        <div
                            className="spinner-border text-secondary spinner-custom"
                            role="status"
                        >
                            <span className="sr-only"></span>
                        </div>
                    </div>
                ) : (
                    <PerfectScrollbar>
                        {carStore.productos.map((p) => {
                            let prodTemp = Productos.find((n) => n.id === p.id);
                            // let totalV = getTotalVariantes(prodTemp, p);
                            let { TotalVariante: totalCV } = getTotalConVariantes(prodTemp, p);
                            return (
                                <div className="wrapItem" key={p.id}>
                                    <div className="item d-flex">
                                        <figure>
                                            <img
                                                src={
                                                    prodTemp &&
                                                    prodTemp.picture_urls[0]
                                                }
                                                alt="img prod"
                                            />
                                        </figure>
                                        <div className="det">
                                            <div className="nombre mb-3">
                                                {prodTemp && prodTemp.name}
                                            </div>
                                            <div className="buttons d-flex">
                                                <ButtonMinus
                                                    car={carStore.productos}
                                                    id={p.id}
                                                />
                                                <span>{p.cantidad}</span>
                                                <ButtonPlus
                                                    car={carStore.productos}
                                                    id={p.id}
                                                />
                                            </div>
                                        </div>
                                        <div className="price d-flex">
                                            <div className="monto mb-1">
                                                {
                                                    prodTemp.custom_data
                                                        .conversion.currency
                                                        .symbol
                                                }
                                                {(totalCV * p.cantidad).toFixed(
                                                    2
                                                )}
                                            </div>
                                            <ButtonTrash id={p.id} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </PerfectScrollbar>
                )}
            </section>
            {Productos !== null && (
                <footer>
                    <div className="info">
                        {Descuento > 0 && (
                            <p className="d-flex justify-content-between">
                                <span>Descuentos</span>{' '}
                                <span>${Descuento.toFixed(2)}</span>
                            </p>
                        )}
                        <p className="d-flex justify-content-between">
                            <b>Subtotal</b>{' '}
                            <b>
                                {
                                    Productos[0].custom_data.conversion.currency
                                        .symbol
                                }
                                {(SubTotal - Descuento).toFixed(2)}
                            </b>
                        </p>
                    </div>
                    <div className="actions">
                        <a
                            onClick={() => {
                                navigate('/realizarPago');
                                dispatch(setShowCar(false));
                            }}
                        >
                            <button>
                                {' '}
                                <span>{t('ContinuarCompra')}</span>
                            </button>
                        </a>
                        <span onClick={() => dispatch(clearCar())}>
                            {t('VaciarCarro')}
                        </span>
                    </div>
                </footer>
            )}
        </section>
    );
};

export default DetailCar;

const getTotalConVariantes = (productoOrigin, pCar) => {
    let _tV = parseFloat(
        formatMonto(
            productoOrigin.custom_data.conversion.display_price_e2,
            2,
            ',',
            '.'
        )
    );
    let _propDet = '';
    Object.keys(pCar.variantes).map((v) => {
        let prop = productoOrigin.branch_good_properties.find(
            (n) => n.property_id === parseInt(v.replace('op_', ''))
        );
        let op = productoOrigin.branch_good_properties
            .find((n) => n.property_id === parseInt(v.replace('op_', '')))
            ?.property_options.find((n) => n.id === pCar.variantes[v]);
        if (op) {
            _tV += parseFloat(formatMonto(op.price_e2, 2, ',', '.'));
        }
        _propDet =
            prop && op
                ? `${prop.display_name}: ${op.name}  (${formatMonto(
                      op.price_e2,
                      2,
                      ',',
                      '.'
                  )})`
                : '';
    });
    return {
        TotalVariante: _tV,
        prop: _propDet
    };
};

// const getTotalVariantes = (productoOrigin, pCar) => {
//     let _tV = 0;
//     Object.keys(pCar.variantes).map((v) => {
//         let op = productoOrigin.branch_good_properties
//             .find((n) => n.id === parseInt(v.replace('op_', '')))
//             ?.property_options.find((n) => n.id === pCar.variantes[v]);
//         if (op) {
//             _tV += parseFloat(formatMonto(op.price_e2, 2, ',', '.'));
//         }
//     });
//     return _tV;
// };
