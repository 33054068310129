import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ title, description, keywords }) => {
    // const metaDescription = description || site.siteMetadata.description;
    // const metaKeywords = keywords || site.siteMetadata.keywords;
    const titleCom = title;

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{titleCom}</title>
            <link rel="canonical" href="http://mysite.com/example" />
            {process.env.REACT_APP_ENV === 'development' ? (
                <script src="https://cdn.lukapay.io/QA/dist/luka.v2.5.min.js"></script>
            ) : (
                <script src="https://cdn.lukapay.io/v2.5/dist/luka.v2.5.min.js"></script>
            )}
        </Helmet>
    );
};

export default Seo;
