import { useEffect, useState } from 'react';
import axios from 'axios';
// import PocketBase from 'pocketbase';

export const useConfig = (domain) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true);
        await axios
            .get(`/localhost.json`)
            // .get(`http://localhost/nodal-shop.ticketmundo.com.json`)
            // .get(`${process.env.REACT_APP_URL_CONFIG}${domain}.json`)
            .then((resp) => {
                setData(resp.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return {
        data,
        error,
        isLoading
    };
};
// export const useConfigPocketBase = (domain) => {
//     const [data, setData] = useState(null);
//     const [error, setError] = useState(null);
//     const [isLoading, setIsLoading] = useState(true);
//     useEffect(() => {
//         getData();
//     }, []);
//     const getData = async () => {
//         setIsLoading(true);
//         const pb = new PocketBase('http://67.205.129.64');
//         const authData = await pb.admins.authWithPassword(
//             'jpaez@ticketmundo.com',
//             'Daikelly0224.'
//         );
//         console.log(authData);
//         // console.log(pb.authStore.isValid);
//         // console.log(pb.authStore.token);
//         // console.log(pb.authStore.model.id);
//         await pb
//             .collection('shop_configs')
//             .getFirstListItem(`domain_name="${domain}"`)
//             .then((resp) => {
//                 console.log(resp)
//                 setData(resp);
//             })
//             .catch((err) => {
//                 setError(err);
//             })
//             .finally(() => {
//                 setIsLoading(false);
//             });
//         // "logout" the last authenticated account
//         pb.authStore.clear();
//     };

//     return {
//         data,
//         error,
//         isLoading
//     };
// };
